
/* Category picker header */
h3 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
    letter-spacing: 1px;
}

/* Unordered list styling for categories */
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* List item for category checkboxes */
li {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

/* Checkbox styling */
input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #007bff;
    transform: scale(1.2);
    /* Makes the checkbox bigger */
}

/* Label styling */
label {
    font-size: 1.1rem;
    color: #555;
}

/* Selected categories section */
h4 {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #007bff;
    text-align: center;
    border-top: 1px solid #007bff;
    padding-top: 10px;
}

/* Selected categories list */
.selected-categories {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-categories ul {
    padding-left: 20px;
}

.selected-categories li {
    margin-bottom: 5px;
    color: #333;
}

/* Empty state when no categories are selected */
.selected-categories p {
    color: #999;
    font-style: italic;
    text-align: center;
}

/* Hover effect on list items */
li:hover label {
    color: #007bff;
    transition: color 0.2s ease-in-out;
}

.category-picker {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}