.cta-button {
    background-color: #ff7f50;
    /* Coral */
    color: white;
    padding: 25px 50px;
    border-radius: 50px;
    margin: 20px;
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
    background-color: #ff6347;
    /* Tomato */
    transform: scale(1.2);
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.3);
}