.event-detail {
    margin: 20px;
    flex:1.5;
    width: 40%;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.event-detail h2 {
    margin-bottom: 10px;
}

.leaflet-container {
    height: 400px;
    position: relative;
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.leaflet-container .leaflet-map-pane {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.info-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.info-card h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
}

.info-card p {
    margin: 5px 0;
    font-size: 0.9em;
    color: #555;
}

ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

ul li {
    margin: 3px;
    padding: 3px;
}

.recommended-events {
    overflow-x: auto;
    margin: 10px;
    padding: 10px;
}

.recommended-event {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    min-width: 200px;
}

.info-card-container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
}