.search-page {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 20px;
    height: 200vh;
    /* Viewport height to make the layout occupy the full screen */
}

.filters {
    margin-bottom: 20px;
}

.filter-options {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.search-layout {
    display: flex;
    gap: 20px;
    height: calc(100vh - 120px);
    /* Adjust height to fit below filters/header */
}



.search-page .event-details {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    /* Can also make this section scrollable if needed */
}
