.application-details {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
}

.application-info {
    margin-bottom: 20px;
}

.documents-section {
    margin: 20px 0;
}

.chat-section {
    margin: 20px 0;
}

.chat-history {
    max-height: 300px;
    overflow-y: auto;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.chat-message {
    margin: 5px 0;
}

.sent {
    text-align: right;
}

.received {
    text-align: left;
}

.chat-sender {
    font-weight: bold;
}

.chat-timestamp {
    font-size: 0.8em;
    color: #999;
}

.chat-input {
    display: flex;
    justify-content: space-between;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.chat-input button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
}

.chat-input button:hover {
    background-color: #0056b3;
}