


    /* Global Styles */
    body {
        font-family: 'Sans-Serif', Arial, sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
        color: #333;
    }

    a {
        text-decoration: none;
        color: #007BFF;
        transition: color 0.3s ease;
    }

    a:hover {
        color: #0056b3;
    }

    button {
        background-color: #007BFF;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    button:hover {
        background-color: #0056b3;
        transform: scale(1.05);
    }

    h1, h2, h3 {
        color: #333;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    /* Landing Page Styles */
    .landing-page {
        text-align: center;
        padding: 20px;
        animation: fadeIn 1s ease-in-out;
    }

    .title {
        font-size: 3rem;
        margin: 10px;
        padding: 10px;
        background-color: #007BFF;
        color: white;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    nav {
        display: flex;
        justify-content: space-around;
        padding: 10px;
        background-color: #007BFF;
        color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .hero {
        background: url('hero-image.jpg') no-repeat center center/cover;
        color: white;
        padding: 100px 20px;
        position: relative;
        animation: slideIn 1s ease-in-out;
    }

    

    .benefits, .testimonials {
        padding: 20px;
        background-color: #f8f9fa;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    footer {
        padding: 20px;
        background-color: #343a40;
        color: white;
        text-align: center;
    }

    /* Vendor Profile Page Styles */
    .vendor-profile {
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
        animation: fadeIn 1s ease-in-out;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

    .business-details, .portfolio, .events, .reviews {
        margin-bottom: 30px;
    }

    h2, h3 {
        border-bottom: 2px solid #007BFF;
        padding-bottom: 5px;
    }

    .photos img {
        width: 100px;
        height: 100px;
        margin: 10px;
        border-radius: 5px;
        transition: transform 0.3s ease;
    }

    .photos img:hover {
        transform: scale(1.1);
    }

    .reviews ul {
        list-style: none;
        padding: 0;
    }

    .reviews li {
        background-color: #f4f4f4;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    /* Event Profile Page Styles */
    .event-profile {
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
        animation: fadeIn 1s ease-in-out;
    }

    .vendor-requirements ul, .slots ul {
        list-style: none;
        padding: 0;
    }

    .vendor-requirements li, .slots li {
        background-color: #f8f9fa;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    /* Search Page Styles */
    .search-page {
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
        animation: fadeIn 1s ease-in-out;
    }


    .event_listing {
        background-color: #e0f7fa;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
        border: 1px solid #00796b;
        transition: transform 0.3s ease;
    }

    .event_listing:hover {
        transform: scale(1.05);
    }

    .vendor_listing {
        background-color: #fff3e0;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
        border: 1px solid #ef6c00;
        transition: transform 0.3s ease;
    }

    .vendor_listing:hover {
        transform: scale(1.05);
    }

    .listing button {
        margin-top: 10px;
    }

    /* Vendor Application Styles */
    .vendor-application {
        padding: 20px;
        max-width: 800px;
        margin: 0 auto;
        animation: fadeIn 1s ease-in-out;
    }

    .vendor-application form label {
        display: block;
        margin-bottom: 10px;
    }

    .vendor-application form input[type="text"],
    .vendor-application form input[type="date"],
    .vendor-application form input[type="file"] {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        transition: border-color 0.3s ease;
    }

    .vendor-application form input[type="text"]:focus,
    .vendor-application form input[type="date"]:focus,
    .vendor-application form input[type="file"]:focus {
        border-color: #007BFF;
    }

    /* Admin Dashboard Styles */
    .admin-dashboard {
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
        animation: fadeIn 1s ease-in-out;
    }

    .quick-stats, .applications {
        margin-bottom: 30px;
    }

    .application {
        background-color: #f8f9fa;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .nav-link {
        color: #333;
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
        background-color: #f4f4f4;
        transition: background-color 0.3s ease;
    }

    .nav-link:hover {
        background-color: #e0e0e0;
    }

    /* Animations */
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes slideIn {
        from {
            transform: translateY(-20px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .fancy-list li {
        opacity: 0;
        /* Hide initially */
        transform: translateX(-50px);
        /* Slide in from the left */
        transition: all 0.6s ease-out;
    }

    /* When the element becomes visible, slide it in */
    .fancy-list li.visible {
        opacity: 1;
        transform: translateX(0);
        /* Slide to its original position */
    }

    .fancy-list {
        list-style: none;
        padding: 0;
    }

    .fancy-list li {
        margin-bottom: 15px;
        font-size: 1.2em;
        line-height: 1.5em;
    }

    .fancy-list li:nth-child(1) {
        transition-delay: 0.1s;
    }

    .fancy-list li:nth-child(2) {
        transition-delay: 0.3s;
    }

    .fancy-list li:nth-child(3) {
        transition-delay: 0.5s;
    }

    .legend {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 10px;
        font-weight: bold;
        color: #333;
    }

    .hero-title {
        background-color: #5194dba4;
        padding: 10px;
        border-radius: 5px;
        margin: 10px;
        display: inline-block;
        font-size: 1.5rem;
        font-weight: bold;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    }

    .hero-intro {
        background-color: #5194dba4;
        padding: 10px;
        border-radius: 5px;
        margin: 10px;
        display: inline-block;
        font-size: 1.5rem;
        font-weight: bold;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    }
    

