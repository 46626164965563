/* userProfile.css */

/* General styling */
.user-profile {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.user-profile header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.user-profile p {
    font-size: 1rem;
    color: #555;
}

/* Tabs styling */
.tabs {
    margin-top: 20px;
    display: flex;
    border-bottom: 1px solid #ddd;
}

.tabs button {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #fff;
    border: none;
    color: #007bff;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    font-size: 1.1rem;
    transition: border-color 0.3s ease-in-out;
}

.tabs button.active {
    border-bottom: 3px solid #007bff;
    background-color: #007bffb9;
    color: #000000;
}

.tabs button:hover {
    border-bottom: 3px solid #007bff;
    color: #000000;
}

/* Tab content styling */
.tab-content {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block;
}

.preferences-tab h2,
.applications-tab h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
}

.preferences-tab p,
.applications-tab p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.6;
}

.preferences-tab ul,
.applications-tab ul {
    list-style-type: disc;
    padding-left: 20px;
}

.preferences-tab ul li,
.applications-tab ul li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
}

.preferences-tab button,
.applications-tab button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.preferences-tab button:hover,
.applications-tab button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Preferences Tab */
.preferences-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preferences-tab label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.preferences-tab input[type="text"],
.preferences-tab input[type="number"],
.preferences-tab input[type="email"],
.preferences-tab select {
    padding: 10px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Application List */
.application-item {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
}

.application-item:hover {
    background-color: #f1f1f1;
}

.application-item h3 {
    font-size: 1.3rem;
    color: #007bff;
    margin: 0;
}

.application-item p {
    font-size: 0.9rem;
    color: #666;
}

/* Application Details */
.application-details {
    margin-top: 20px;
}

.application-details h2 {
    font-size: 1.7rem;
    margin-bottom: 10px;
}

.application-details p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
}

.application-details h3 {
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

.application-details ul {
    list-style-type: disc;
    padding-left: 20px;
}

.application-details ul li {
    font-size: 1rem;
    color: #555;
}

/* Chat thread */
.chat-thread {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;
}

.chat-message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
}

.chat-message.user {
    background-color: #e6f7ff;
    color: #007bff;
}

.chat-message.event {
    background-color: #f9f9f9;
    color: #333;
}

/* Chat input */
textarea {
    width: 50%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

button {
    padding: 10px 15px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.row {
    width: auto;
    display: flex;
    justify-content: space-between;
}