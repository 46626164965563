.event-form {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    display: flex;
    flex-direction: row; 
    align-items: stretch;
    overflow: auto;
    max-height: 150vh;
}


.event-details {
    display: flex;
    flex-direction: column;
    margin: 20px;
    animation: flash .5s ease-in;
    animation-iteration-count: 2;
    flex:1.5;
}


form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}


@keyframes flash {
    0%, 100% {
        opacity: 1;
        background-color: #f9f9f9;
    }
    50% {
        opacity: 0.7;
        background-color: #cfe0ff;
    }
}

input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}


.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.form-buttons button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

textarea {
    width: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.tag-picker-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 10px;
}