.question-flow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.step {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 15px;
}

.next-button, .submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.next-button:hover, .submit-button:hover {
    background-color: #0056b3;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToRight {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.step-enter {
    animation: slideInFromLeft 0.2s forwards;
}

.step-exit {
    animation: slideOutToRight 0.2s forwards;
}