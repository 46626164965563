



.admin-dashboard .tabs {
    display: flex;
    position: sticky;
    justify-content: space-around;
    margin: 10px;
    z-index: 1000;
}

.admin-dashboard .tabs button {
    background-color: #f4f4f4;
    width: 45%;
}

.admin-dashboard .tabs button.active {
    background-color: #007BFF;
    color: white;
    transition: background-color 0.3s;
}

.admin-dashboard {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
