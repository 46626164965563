.show-more {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.3s;
    width: fit-content;
}

.search-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 20px;
    align-items: center;
}

.search-bar input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.search-bar TextField {
    flex:2;
}

.serach-bar label {
    flex:1;
}

.search-results {

    position: relative;
    overflow-y: auto;
    min-height: 0;
}

.search {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
}

.listing {
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 10px;
    padding: 10px;
    
}

.listing:hover {
    background-color: #f1f1f1;
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.market_size {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;

}

.main_filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.main_filters label {
    margin: 10px;
}

.market_size label {
    margin: 10px;
}

.date_range {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}